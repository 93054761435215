import { memo } from "react"
import Cal from "https://ga.jspm.io/npm:@calcom/embed-react@1.5.2/dist/Cal.es.mjs"
import { ControlType, addPropertyControls } from "framer"

// Define styles as a constant
const calStyles = {
    width: "100%",
    height: "100%",
    overflow: "scroll",
}

/**
 * This memoized version of the Cal component
 */
const MemoizedCal = memo(({ calLink }) => {
    return <Cal calLink={calLink} style={calStyles} />
})

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
function CalBooking({ calLink }) {
    return <MemoizedCal calLink={calLink} />
}

// Adding property controls
addPropertyControls(CalBooking, {
    calLink: {
        title: "Kalender Link",
        defaultValue: "krauss/15min",
        type: ControlType.String,
    },
})

export default CalBooking
